import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import RoomDetailsData from "../components/RoomDetailsData";
import DelexeRoomSlider from "../components/DeluxeRoomSlider";
const RoomDetails3 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <DelexeRoomSlider />
      <RoomDetailsData roomName="Deluxe Room" price={"$170"} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails3;
