import React from "react";
import { Link } from "react-router-dom";

const RestaurantHero = () => {
  return (
    <div>
      <section className="padding py-[50px] flex text-center items-center justify-center flex-col">
        <p className="sm:mt-[30px] mt-[20px] poppins sm:text-[16px] text-[14px] leading-[19px] text-deepBrown font-normal uppercase">
          exquisite fine dining
        </p>

        <h1 className="sm:mt-[20px] mt-[10px] sm:text-[49px] text-[35px] text-orange mar font-normal sm:leading-[58px] leading-[40px]">
          Welcome to Juban <br className="hidden md:flex" />
          Restaurant
        </h1>
        <p className="sm:mt-[42px] mt-[20px] sm:mb-[42px] mb-[20px] max-w-[675px] text-lightBrown text-[16px] poppins tracking-[0.3px]">
          Experience the vibrant fusion of cultures in Ghana at Juban
          Restaurant. Indulge in our exquisite fine dining, where cultural and
          continental cuisines blend harmoniously. Discover the flavors that
          make Ghana a culinary delight.
        </p>
      </section>
      <section className="flex overflow-hidden slider mt-[30px]">
        <div className="slider-container">
          <img
            src="/restaurant/restaurant1.jpg"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
            loading="lazy"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
            loading="lazy"
          />
          <img
            loading="lazy"
            src="/restaurant/restaurant3.jpg"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant4.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant1.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            alt="gallery-slide"
            loading="lazy"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant3.jpg"
            alt="gallery-slide"
            loading="lazy"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant4.jpg"
            alt="gallery-slide"
            loading="lazy"
            className="w-[432px] h-[461px] object-cover"
          />{" "}
          <img
            src="/restaurant/restaurant1.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            loading="lazy"
            src="/restaurant/restaurant3.jpg"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant4.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />{" "}
          <img
            src="/restaurant/restaurant1.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant3.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant4.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />{" "}
          <img
            src="/restaurant/restaurant1.jpg"
            alt="gallery-slide"
            loading="lazy"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant3.jpg"
            loading="lazy"
            alt="gallery-slide"
            className="w-[432px] h-[461px] object-cover"
          />
          <img
            src="/restaurant/restaurant4.jpg"
            alt="gallery-slide"
            loading="lazy"
            className="w-[432px] h-[461px] object-cover"
          />
        </div>
      </section>

      <p className="py-[40px] text-center text-[16px] underline hover:opacity-[1] text-orange transition opacity-[0.6] poppins">
        <Link to={"/gallery"}>View more</Link>
      </p>
    </div>
  );
};

export default RestaurantHero;
