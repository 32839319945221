import React, { useRef } from "react";

const galleryImages = [
  "/room-d/room1.jpg",
  "/room-d/room2.jpg",
  "/room-d/room3.jpg",
  "/room-d/room4.jpg",
];

const DeluxeRoomSlider = () => {
  const scrollRef = useRef(null);
  const imageWidth = 100;

  const scroll = (direction) => {
    const { current } = scrollRef;
    const containerWidth = current.offsetWidth;

    // Calculate the scroll amount based on the direction
    const scrollAmount =
      direction === "left" ? -containerWidth : containerWidth;

    // Scroll to the next or previous slide
    current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  const scrollToImage = (index) => {
    const { current } = scrollRef;

    // Calculate the scroll position based on the index of the image
    const scrollPosition = index * imageWidth;

    console.log("Scroll Position:", scrollPosition); // Add this logging

    // Scroll to the specified position
    current.scrollTo({
      left: scrollPosition,
      behavior: "smooth",
    });
  };

  return (
    <section>
      <section className="relative">
        <div className="app__gallery__images ">
          <div
            className="app__gallery__images__container scrollbar3"
            ref={scrollRef}
            style={{ display: "flex", flexDirection: "row" }}
          >
            {galleryImages.map((image, index) => (
              <div
                className="app__gallery__image__card flex__center"
                key={index}
                style={{ flex: "0 0 auto", width: "100%" }}
              >
                <img
                  src={image}
                  alt="gallery_image"
                  loading="lazy"
                  style={{ width: "100%" }}
                />
              </div>
            ))}
          </div>
          <div className="app__gallery__arrows">
            <img
              src="/images/arrow-left.svg"
              alt="arrow"
              className="arrow"
              onClick={() => scroll("left")}
            />
            <img
              src="/images/arrow-right.svg"
              alt="arrow"
              className="arrow"
              onClick={() => scroll("right")}
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default DeluxeRoomSlider;
