import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import RoomDetailsData from "../components/RoomDetailsData";
import TwinRoomSlider from "../components/TwinRoomSlider";

const RoomDetails4 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <TwinRoomSlider />
      <RoomDetailsData roomName="Twin Room" price={"$160"} adult_max={"4"} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails4;
