import React from "react";
import Navbar from "../components/Navbar";
import Header from "../components/Header";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <section>
      <Navbar pageTitle={"Contact Us"} />
      <Header pageTitle={"Contact Us"} classTitle={"gallery-bg"} />
      <section className="padding flex justify-between mt-[50px] flex-wrap">
        <h1 className="sm:mt-[20px] mt-[10px] sm:text-[49px] text-[35px] text-orange mar font-normal sm:leading-[58px] leading-[40px]">
          Don’t hesitate to contact <br className="hidden md:flex" />
          us if you need more help.
        </h1>
        <section className="flex gap-[50px] xl:pl-[103px] lg:pl-[60px] md:pl-[30px] flex-wrap">
          <div className="flex flex-col items-start ">
            <div>
              <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                Contact info
              </h2>
              <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                (+233) 56 163 3542
              </p>
            </div>
            <div className="mt-[57px]">
              <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                Office Numbers
              </h2>
              <p className="text-[16px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                (+233) 30 320 5547 <br />
                (+233) 30 320 5680 <br />
                (+233) 30 321 1942
              </p>
            </div>
          </div>

          <div className="flex flex-col items-start">
            <div>
              <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                Booking/Reservations
              </h2>
              <p className="sm:text-[16px] text-[14px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                info@crismonhotel.com
                <br /> reservations@crismonhotel.com
              </p>
            </div>
            <div className="mt-[31px]">
              <h2 className="text-darkBrown text-[20px] font-semibold leading-[32px] poppins tracking-[0.3px] mb-[8px]">
                Departments
              </h2>
              <p className="sm:text-[16px] text-[15px] poppins text-[#4F4F4F]tracking-[0.3px] leading-[22px]font-normal">
                afedzi@crismonhotel.com <br />
                accounts@crismonhotel.com <br />
                baaban@crismonhotel.com <br />
                aban@crismonhotel.com <br />
                keith-alvah@crismonhotel.com <br />
                abi@crismonhotel.com
              </p>
            </div>
          </div>
        </section>
      </section>

      <section className="mt-[50px] mb-[3rem] padding">
        <h1 className="text-darkBrown md:text-[49px] sm:text-[35px] text-[30px] md:leading-[58px] sm:leading-[46px] leading-[35px] mar mb-[20px] text-center">
          Write to us
        </h1>
      </section>

      <section className="flex padding gap-[40px] sm:flex-row flex-col">
        <input
          type="email"
          placeholder="Email Address*"
          className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
        />
        <input
          type="email"
          placeholder="Full Name*"
          className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
        />
        <input
          type="text"
          placeholder="Subject"
          className="border-b border-[#C5BFB1] bg-transparent outline-none sm:text-center py-2  w-full placeholder-[#A69C86] text-[16px] poppins"
        />
      </section>

      <section className="flex flex-col items-center  padding mt-[50px] justify-center mb-[40px]">
        <textarea
          type="text"
          placeholder="Message*"
          className="border-[#C5BFB1] bg-transparent outline-none  placeholder-[#A69C86] text-[16px] poppins border md:w-[70%] w-full py-[1rem] px-[2rem] rounded-[20px] h-[300px]"
        />
        <button className="px-[24px] py-[16px] bg-orange  rounded-[100px] mt-[38px] text-white text-[16px] poppins leading-[19px] hover:text-orange hover:bg-transparent hover:border border-orange ">
          Send Message
        </button>
      </section>

      <section className="padding mt-[30px] mb-[40px]">
        <div>
          <iframe
            width="100%"
            height="600"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=JXRG+R9J%20Crismon%20Hotel,%20Tema+(Crismon%20Hotel)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          >
            <a href="https://www.gps.ie/">gps trackers</a>
          </iframe>
        </div>
      </section>
      <Newsletter />
      <Footer />
    </section>
  );
};

export default Contact;
