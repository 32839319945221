import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import RoomDetailsData from "../components/RoomDetailsData";
import JuniorSuiteRoomSlider from "../components/JuniorSuiteRoomSlider";

const RoomDetails2 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <JuniorSuiteRoomSlider />
      <RoomDetailsData roomName="Junior Suite" price={"$215"} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails2;
