import React from "react";
import { Link } from "react-router-dom";

const Explore = () => {
  return (
    <section className="padding">
      {/* First Section */}

      <section className="grid sm:grid-cols-2 items-center md:gap-[56px] gap-[30px]  sm:py-[73px] py-[30px]">
        <div className=" w-full  flex flex-col sm:justify-end ">
          <p className="uppercase text-deepBrown_100 sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-orange mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Restaurant
          </h2>
          <p className="text-lightBrown poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            Step into a world of culinary delight at Crismon Hotel. Our
            restaurant offers a blend of local and international flavors,
            creating an exquisite dining experience. From casual bites to fine
            dining, discover a taste of excellence in every dish. Join us for a
            memorable meal that transcends expectations.
          </p>
          <div className="flex sm:gap-[52px] gap-[1rem] items-end flex-wrap">
            <Link to={"/restaurant"}>
              <p className="text-deepBrown_100 border-b border-deepBrown_100 w-[106px] cursor-pointer text-[16px] hover:border-none transition poppins">
                View More
              </p>
            </Link>
            <Link to={"/restaurant"}>
              <button className=" text-center text-deepBrown_100 border border-deepBrown_100 px-[30px] py-[10px] text-[16px] rounded-[100px] hover:bg-deepBrown_100  hover:text-[#fff] poppins transition">
                View Menu
              </button>
            </Link>
          </div>
        </div>

        <img
          src="/restaurant/restaurant3.jpg"
          alt="explore"
          className="w-full md:h-[700px] h-full object-cover"
        />
      </section>

      {/* Second Section */}
      <section className="grid sm:grid-cols-2 items-center md:gap-[56px] gap-[30px] sm:py-[73px] py-[30px]">
        <img
          src="/views/view4.jpg"
          alt="explore"
          className="w-full md:h-[700px] h-full object-cover sm:flex hidden"
        />
        <div className=" w-full  flex flex-col sm:justify-end ">
          <p className="uppercase text-deepBrown_100 sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-orange mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Environment
          </h2>
          <p className="text-lightBrown poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            At Crismon Hotel, we cherish the harmony between comfort and nature.
            Our commitment to the environment is reflected in every aspect of
            our space. From eco-friendly practices to lush surroundings, we've
            created a haven that respects and embraces nature. Join us in
            preserving the beauty that surrounds Crismon Hotel and experience a
            stay where sustainability meets serenity.
          </p>
        </div>
        <img
          src="/views/view4.jpg"
          alt="explore"
          className="w-full md:h-[700px] h-full object-cover sm:hidden"
        />
      </section>

      {/* Third */}
      <section className="grid sm:grid-cols-2 items-center md:gap-[56px] gap-[30px]  sm:py-[73px] py-[30px]">
        <div className=" w-full  flex flex-col sm:justify-end ">
          <p className="uppercase text-deepBrown_100 sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-orange mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Meetings & Events
          </h2>
          <p className="text-lightBrown poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            Our vibrant and impactful venue is crafted to take your meetings,
            conferences, and special events to new levels of excellence. Make a
            significant impression and ensure a memorable experience for your
            attendees with our outstanding facilities and impeccable service.
          </p>
          <div className="flex sm:gap-[52px] gap-[1rem] items-end flex-wrap">
            <Link to={"/meetings&events"}>
              <p className="text-deepBrown_100 border-b border-deepBrown_100 w-[106px] cursor-pointer text-[16px] hover:border-none transition poppins">
                View More
              </p>
            </Link>
          </div>
        </div>

        <img
          src="/events/event2.jpg"
          alt="explore"
          className="w-full md:h-[700px] h-full object-cover"
        />
      </section>
    </section>
  );
};

export default Explore;

/* 
 <section className="">
      <section className="bg-explore-first">
        <div className="blur-surface1 lg:w-[40%] md:w-[60%] sm:w-[80%] w-full sm:py-[120px] py-[70px] paddingLeft flex flex-col sm:justify-end xl:pr-[60px] sm:pr-[30px] pr-[1rem] sm:min-h-[846px]">
          <p className="uppercase text-white sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-white mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Restaurant
          </h2>
          <p className="text-white poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            Step into a world of culinary delight at Crismon Hotel. Our
            restaurant offers a blend of local and international flavors,
            creating an exquisite dining experience. From casual bites to fine
            dining, discover a taste of excellence in every dish. Join us for a
            memorable meal that transcends expectations.
          </p>
          <div className="flex sm:gap-[52px] gap-[1rem] items-end flex-wrap">
            <Link to={"/restaurant"}>
              <p className="text-white border-b w-[106px] cursor-pointer text-[16px] hover:border-none transition poppins">
                View More
              </p>
            </Link>
            <Link to={"/restaurant"}>
              <button className=" text-center text-white border border-white px-[30px] py-[10px] text-[16px] rounded-[100px] hover:bg-white hover:text-[#000] poppins transition">
                View Menu
              </button>
            </Link>
          </div>
        </div>
      </section>
     
      <section className="bg-explore-second flex flex-col sm:justify-end sm:items-end ">
        <div className="blur-surface2 lg:w-[40%] md:w-[60%] sm:w-[80%] w-full sm:py-[120px] py-[70px] paddingRight flex flex-col sm:justify-end xl:pl-[60px] sm:pl-[30px] pl-[1rem] sm:min-h-[846px]">
          <p className="uppercase text-white sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-white mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Environment
          </h2>
          <p className="text-white poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            At Crismon Hotel, we cherish the harmony between comfort and nature.
            Our commitment to the environment is reflected in every aspect of
            our space. From eco-friendly practices to lush surroundings, we've
            created a haven that respects and embraces nature. Join us in
            preserving the beauty that surrounds Crismon Hotel and experience a
            stay where sustainability meets serenity.
          </p>
        </div>
      </section>

     

      <section className="bg-explore-third">
        <div className="blur-surface3 lg:w-[40%] md:w-[60%] sm:w-[80%] w-full sm:py-[120px] py-[70px] paddingLeft flex flex-col sm:justify-end xl:pr-[60px] sm:pr-[30px] pr-[1rem] sm:min-h-[846px]">
          <p className="uppercase text-white sm:text-[16px]  text-[14px] leading-[19px] poppins sm:mb-[20px] mb-[10px]">
            Explore
          </p>
          <h2 className="text-white mar sm:text-[49px] text-[35px] leading-[58px] font-normal sm:mb-[47px] mb-[10px]">
            Meetings & Events
          </h2>
          <p className="text-white poppins tracking-[0.3px] leading-[22px] sm:mb-[47px] mb-[20px] sm:text-[16px] text-[15px]">
            Our vibrant and impactful venue is crafted to take your meetings,
            conferences, and special events to new levels of excellence. Make a
            significant impression and ensure a memorable experience for your
            attendees with our outstanding facilities and impeccable service.
          </p>
          <div className="flex sm:gap-[52px] gap-[1rem] items-end flex-wrap">
            <Link to={"/meetings&events"}>
              <p className="text-white border-b w-[106px] cursor-pointer text-[16px] hover:border-none transition poppins">
                View More
              </p>
            </Link>
          </div>
        </div>
      </section>
    </section>
     
*/
