import instance from "./api";

const handleBooking = async (from_name, email, telephone, check_in, check_out, room, guests, cost) => {
    const request = {
        "from_name": from_name,
        "email": email,
        "telephone": telephone,
        "check_in": check_in,
        "check_out": check_out,
        "room": room,
        "guests": guests,
        "cost": cost
    }
    try {
        const response = await instance.post("http://localhost/hotel/server/sendmail.php", request);
        return response.data; //boolean
    }
    catch (err) {
        console.log(err);
        return false;
    }
};

export default handleBooking;