import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

import RoomDetailsData from "../components/RoomDetailsData";
import StandardRoomSlider from "../components/StandardRoomSlider";

const RoomDetails6 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <StandardRoomSlider />
      <RoomDetailsData roomName="Standard Room" price={"$130"} />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails6;
