import React, { useEffect, useRef, useState } from "react";
import "yet-another-react-lightbox/styles.css";
import {
  Captions,
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Lightbox from "yet-another-react-lightbox";
import { slidesData } from "../constants/data";

const GalleryCom = () => {
  const [showImage, setShowImage] = useState(null);
  const modalRef = useRef(null);
  useEffect(() => {
    if (showImage) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showImage, setShowImage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowImage(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setShowImage, showImage]);

  const [index, setIndex] = useState(-1);
  return (
    <section className="padding py-[20px]">
      <Lightbox
        plugins={[Captions, Download, Fullscreen, Zoom, Thumbnails]}
        captions={{
          showToggle: true,
          descriptionTextAlign: "end",
        }}
        // open={open}
        // close={() => setOpen(false)}

        index={index}
        open={index >= 0}
        close={() => setIndex(-1)}
        slides={slidesData}
      />
      {showImage && (
        <section className="fixed top-0 left-0 w-full h-full z-[999999] modal p-[50px]">
          <img
            src="/images/close2.svg"
            alt="close"
            onClick={() => setShowImage(null)}
            className="cursor-pointer w-[25px] h-fit absolute sm:top-[20px] top-[15px] sm:right-[40px] right-[20px]"
          />
          <div ref={modalRef} className="w-full h-full">
            <img
              src={showImage}
              alt="img"
              className="w-full h-full object-contain"
            />
          </div>
        </section>
      )}
      <div className="flex sm:gap-[16px] gap-[26px] md:justify-center justify-start items-center overflow-auto text-nowrap">
        <a
          href="#hotel-view"
          className="text-lightBrown poppins tracking-[0.3px] leading-[22px] py-[10px] border-b-[2px] border-lightBrown flex-shrink-0"
        >
          Hotel View
        </a>
        <a
          href="#rooms&suits "
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Rooms & Suits
        </a>
        <a
          href="#recreation&fitness"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Recreation & Fitness
        </a>
        <a
          href="#restaurant"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Restaurant
        </a>
        <a
          href="#events&meetings"
          className="text-[#C5BFB1] poppins tracking-[0.3px] leading-[22px] py-[10px] hover:border-b-[2px] hover:border-lightBrown hover:text-lightBrown"
        >
          Events & Meeting
        </a>
      </div>

      <section className="sm:pt-[60px] pt-[30px]" id="hotel-view">
        <div className="flex md:gap-[100px] sm:gap-[50px] gap-[20px] items-center sm:mb-[44px] mb-[24px] flex-wrap">
          <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] ">
            Hotel View
          </h1>
          <button
            onClick={() => setIndex(0)}
            className=" text-center text-deepBrown_100 border border-deepBrown_100 px-[30px] py-[10px] text-[16px] rounded-[100px] hover:bg-deepBrown_100  hover:text-[#fff] poppins transition"
          >
            View Slides
          </button>
        </div>
        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/views/view2.jpg"
            alt="hotel_image"
            onClick={() => setShowImage("/views/view2.jpg")}
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover hover:scale-[1.01] cursor-pointer"
            loading="lazy"
          />
          <img
            src="/views/view4.jpg"
            onClick={() => setShowImage("/views/view4.jpg")}
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            loading="lazy"
          />
          <img
            src="/exterior/ex1.jpg"
            onClick={() => setShowImage("/exterior/ex1.jpg")}
            alt="hotel_image"
            loading="lazy"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/exterior/ex2.jpg"
              onClick={() => setShowImage("/exterior/ex2.jpg")}
              loading="lazy"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
            <img
              src="/exterior/ex3.jpg"
              loading="lazy"
              onClick={() => setShowImage("/exterior/ex3.jpg")}
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
          </div>

          <img
            src="/exterior/ex4.jpg"
            onClick={() => setShowImage("/exterior/ex4.jpg")}
            loading="lazy"
            alt="hotel"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
        </div>
      </section>

      {/* Rooms &  suits*/}

      <section className="pt-[100px]" id="rooms&suits">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Rooms & Suits
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/room-a/room2.jpg"
            onClick={() => setShowImage("/room-a/room2.jpg")}
            loading="lazy"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/room-b/room2.jpg"
            onClick={() => setShowImage("/room-b/room2.jpg")}
            loading="lazy"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/room-g/room3.jpg"
            onClick={() => setShowImage("/room-g/room3.jpg")}
            loading="lazy"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <img
            src="/room-d/room2.jpg"
            onClick={() => setShowImage("/room-d/room2.jpg")}
            loading="lazy"
            alt="hotel"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/room-e/room2.jpg"
              onClick={() => setShowImage("/room-e/room2.jpg")}
              alt="hotel"
              loading="lazy"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
            <img
              src="/room-f/room2.jpg"
              onClick={() => setShowImage("/room-f/room2.jpg")}
              loading="lazy"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
          </div>
        </div>
      </section>

      {/* Fitness */}

      <section className="pt-[100px]" id="recreation&fitness">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Recreation & Fitness
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/reception/reception1.jpg"
            onClick={() => setShowImage("/reception/reception1.jpg")}
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            loading="lazy"
          />
          <img
            src="/reception/reception2.jpg"
            onClick={() => setShowImage("/reception/reception2.jpg")}
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            loading="lazy"
          />
          <img
            src="/reception/reception3.jpg"
            onClick={() => setShowImage("/reception/reception3.jpg")}
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            loading="lazy"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <img
            src="/reception/reception6.jpg"
            alt="hotel"
            onClick={() => setShowImage("/reception/reception6.jpg")}
            loading="lazy"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/reception/reception5.jpg"
              alt="hotel"
              onClick={() => setShowImage("/reception/reception5.jpg")}
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
            <img
              src="/reception/reception4.jpg"
              onClick={() => setShowImage("/reception/reception4.jpg")}
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
          </div>
        </div>
      </section>
      {/* Restaurant */}
      <section className="pt-[100px]" id="restaurant">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Restaurant
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/restaurant/restaurant1.jpg"
            alt="hotel_image"
            onClick={() => setShowImage("/restaurant/restaurant1.jpg")}
            loading="lazy"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/restaurant/restaurant2.jpg"
            onClick={() => setShowImage("/restaurant/restaurant2.jpg")}
            alt="hotel_image"
            loading="lazy"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/restaurant/restaurant3.jpg"
            onClick={() => setShowImage("/restaurant/restaurant3.jpg")}
            alt="hotel_image"
            loading="lazy"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <img
            src="/restaurant/restaurant4.jpg"
            onClick={() => setShowImage("/restaurant/restaurant4.jpg")}
            alt="hotel"
            loading="lazy"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            {/* <img
              src="/restaurant/restaurant2.jpg"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            />
            <img
              src="/restaurant/restaurant1.jpg"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover"
            /> */}
          </div>
        </div>
      </section>
      {/* Events */}
      <section className="pt-[100px] mb-[50px]" id="events&meetings">
        <h1 className="sm:text-[31px] text-[25px] mar leading-[37px] text-[#000] mb-[44px]">
          Events & Meeting
        </h1>

        <div className="flex lg:gap-[17px] gap-[10px] flex-shrink flex-col md:flex-row">
          <img
            src="/events/event1.jpg"
            onClick={() => setShowImage("/events/event1.jpg")}
            alt="hotel_image"
            loading="lazy"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/events/event2.jpg"
            onClick={() => setShowImage("/events/event2.jpg")}
            loading="lazy"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <img
            src="/events/event3.jpg"
            onClick={() => setShowImage("/events/event3.jpg")}
            loading="lazy"
            alt="hotel_image"
            className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
        </div>

        <div className="lg:mt-[17px] mt-[10px] flex flex-col md:flex-row lg:gap-[17px] gap-[10px] flex-shrink">
          <img
            src="/events/event4.jpg"
            onClick={() => setShowImage("/events/event4.jpg")}
            alt="hotel"
            loading="lazy"
            className="xl:w-[906px] lg:w-[716px] md:w-[617px] w-full xl:h-[622px] lg:h-[518px] md:h-[418px] h-full object-cover cursor-pointer hover:scale-[1.01]"
          />
          <div className="flex flex-col lg:flex-shrink-0 lg:gap-[17px] gap-[10px]">
            <img
              src="/events/event5.jpg"
              onClick={() => setShowImage("/events/event5.jpg")}
              alt="hotel"
              loading="lazy"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
            <img
              src="/events/event6.jpg"
              onClick={() => setShowImage("/events/event6.jpg")}
              loading="lazy"
              alt="hotel"
              className="xl:w-[444px] lg:w-[350px] md:w-[300px]  w-full xl:h-[301px] lg:h-[250px] md:h-[200px] h-full object-cover cursor-pointer hover:scale-[1.01]"
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default GalleryCom;
