import React from "react";
import Navbar from "../components/Navbar";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";

import RoomDetailsData from "../components/RoomDetailsData";
import ExecutiveSuiteSlider from "../components/ExecutiveSuiteSlider";

const RoomDetails7 = () => {
  return (
    <div>
      <Navbar pageTitle={"Room & Suits"} />
      <ExecutiveSuiteSlider />
      <RoomDetailsData
        roomName="Executive Suite"
        price={"$400"}
        adult_max={"4"}
      />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default RoomDetails7;
