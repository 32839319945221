import React from "react";
import { Link } from "react-router-dom";
import { slidesData } from "../constants/data";

const AboutGallery = () => {
  return (
    <section>
      <section className="padding py-[80px] flex text-center items-center justify-center flex-col">
        <img
          src="/images/logo-dark.svg"
          alt="logo"
          className="w-[115px] object-cover h-[101px]"
        />

        <p className="sm:mt-[50px] mt-[40px] poppins sm:text-[16px] text-[14px] leading-[19px] text-deepBrown font-normal">
          AKWAABA - WELCOME TO CRISMON HOTEL
        </p>

        <h1 className="sm:mt-[20px] mt-[10px] sm:text-[49px] text-[35px] text-orange mar font-normal sm:leading-[58px] leading-[40px]">
          Home of style & Comfort
        </h1>
        <p className="sm:mt-[42px] mt-[20px] sm:mb-[42px] mb-[20px] max-w-[770px] text-lightBrown text-[16px] poppins tracking-[0.3px]">
          Experience the exceptional Crismon Hotel, renowned as one of the best
          hotels in Ghana. Situated in Tema Community 5 on 37 Tench Street, this
          hidden gem offers a touch of luxury at a competitive price. With
          outstanding amenities and a focus on affordability, Crismon Hotel is
          the ideal choice for your stay in Ghana. Book now and indulge in a
          remarkable experience without breaking the bank.
        </p>
      </section>

      <section className="flex overflow-hidden slider">
        <div className="slider-container">
          {slidesData?.map((item, idx) => (
            <img
              key={idx}
              src={item.src}
              alt="gallery-slide"
              className="w-[70%] h-[461px] object-cover"
            />
          ))}
        </div>
      </section>

      <p className="opacity-[0.6] text-[20px] poppins tracking-[0.3px] text-orange text-center py-[42px] hover:opacity-[1] cursor-pointer transition flex justify-center w-[200px] m-auto">
        <Link to={"/gallery"}>View more</Link>
      </p>
    </section>
  );
};

export default AboutGallery;
